@import url("https://fonts.googleapis.com/css?family=Inter:300,300i,400,400i,500,500i,700,700i,900,900i");

* {
  font-family: 'Helvetica', sans-serif;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#loginPage {
  .title {
    font-size: 24px;
    font-weight: 500;
    color: #313A4B;
  }

  .login-button {
    width: 100%;
    background-color: #003A70;
    border-radius: 8px;
    color: #fff;
  }
}

.loginContainer{
  max-width: 100% !important;
  padding-left: 0;
  padding-right: 0;
}

.gencat-img{
  position: absolute;
  bottom: 0;
  right: 90px;
  height: 30px;
  margin-bottom: 60px;
}

.footer-container {
  position: absolute;
  bottom: 0;
  width: 100%;
  margin-top: 2em;
  padding: 10px;
}

#appbar {
  .middle-section {
    .ant-btn-link {
      color: #717D96;
      font-size: 16px;
      font-weight: 700;
    }
  }
}

.conversation-buttons-container{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}

.conversation-button {
  border: 2px solid #ff4d4d;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: #fff;
  margin-inline: 0.5em;
  &.record-button{
    background-color: #ff4d4d;
    border: 2px solid #ff4d4d;
    width: 60px !important;
    height: 60px;
  }
  
  &.next-undo-buttons{
    background-color: #000000;
    border: 2px solid #000000;
    width: 50px !important;
    height: 50px;
  }
}

.conversation-button:hover {
  &.record-button{
    background-color: #ff4d4d;
    color: #fff !important;
    border: 2px solid #ff4d4d !important;
  }
  &.next-undo-buttons{
    background-color: #000000;
    color: #fff !important;
    border: 2px solid #000000 !important;
  }
}

.conversation-button:disabled{
  color: #fff;
  background-color: #d2d2d2;
  border: 2px solid #d2d2d2 !important;
  &:hover{
    color: #fff;
    background-color: #d2d2d2;
    border: 2px solid #d2d2d2 !important;
  }
}

.conversation-button:focus {
  outline: none;
}

.conversation-container {
  .ant-input[disabled],.ant-input {
    border-radius: 4px;
    background: #E6E6E6; 
    color: #000;
    font-size: 18px;
    font-weight: 400;
  }
}

.conversation-recorder {
  .elapsed-time {
    color: #000;
    font-size: 40px; 
    font-weight: 600; 
  }
}

.record-button-label{
  display: flex;
  justify-content: center;
}

.finish-buttons{
  margin-top: 20px;
}